.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-image: url("./Yolobg.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
