.hamburger-menu {
  /* position: relative; */
  margin: 15px;
}

.menu-icon {
  display: inline-block;
  cursor: pointer;
  width: 30px;
  height: 24px;
  position: relative;
  z-index: 2;
}

.menu-icon div {
  background-color: #333;
  height: 4px;
  width: 100%;
  margin: 4px 0;
  transition: 0.4s;
}

.menu-icon.open .bar1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-icon.open .bar2 {
  opacity: 0;
}

.menu-icon.open .bar3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.menu-nav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;
  z-index: 1;
  overflow: hidden;
}

.menu-nav.open {
  transform: translateX(0);
}

.menu-nav ul {
  list-style: none;
  text-align: center;
}

.menu-nav ul li {
  margin: 20px 0;
}

.menu-nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.menu-nav ul li a:hover {
  color: #ff6347;
}
