.card {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 65%;
  margin: auto;
  overflow: hidden;
  /* transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; */
}

.card-headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
}

.context {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
/* .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
} */

.card-image {
  max-width: 100%;
  height: 250px;
  object-fit: cover;
}

.card-content {
  display: flex;
  flex-direction: column;
}

.card-title {
  font-size: 1.5rem;
}

.social-links {
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
}

@media (max-width: 768px) {
  .card-content {
    flex-direction: column;
  }

  .card {
    width: 80%;
  }

  .context {
    flex-direction: column;
  }

  .card-headers {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .card-content {
    flex-direction: column;
    padding: 10px;
  }

  .card {
    width: 90%;
  }

  .context {
    flex-direction: column;
  }

  .card-headers {
    flex-direction: column;
  }
}
