.navCont {
  margin-top: 0.5rem;
  width: 60%;
  height: 75px;
  background-color: #fff;
  border-radius: 10px;
  border: solid 5px black;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

a {
  text-decoration: none;
  color: black;
}
